import React, { useState, useEffect, useRef } from "react";
import { Box, Tab, Tabs, capitalize } from "@mui/material";
import MiniMap from "./MiniMap";
import medications from "./medications";
import diseases from "./diseases";
import NewDiseasesBox from "./NewDiseasesBox";
import NewMedicationBox from "./NewMedicationBox";
import Chatbot from "./Chatbot";
import { MainContainer } from "@chatscope/chat-ui-kit-react";

const ContextBox = ({
  branding,
  messages,
  user,
  userData,
  tabs,
  setTabs,
  currentTab,
  setCurrentTab,
  expanded,
  setExpanded,
  boxRef,
}) => {
  const navRef = useRef(null);
  const [previewTab, setPreviewTab] = useState(tabs[tabs.length - 1]);
  const [medicationsList, setMedicationsList] = useState([]);
  const [diseasesList, setDiseasesList] = useState([]);

  const [xsSize, setXsSize] = useState("calc(20dvh - 24px)");
  const [smSize, setSmSize] = useState("calc(20dvh - 24px)");

  let chatXsSize = "calc(80dvh - 60px)";
  let chatSmSize = "80dvh";

  if (tabs.length === 0) {
    chatXsSize = "calc(100dvh - 86px)";
    chatSmSize = "calc(100dvh - 24px)";
  }

  const openTab = (tab) => {
    setCurrentTab(tab);
    setExpanded(true);
  };

  // Should we highlight this tab?
  const isHighlighted = (tab) => (previewTab === tab && !expanded ? { color: "#1B4584" } : null);

  // Should we show the contents of the tab?
  const isVisible = (tab) => currentTab === tab || (previewTab === tab && !expanded);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);

    if (newValue === "home") {
      setExpanded(false);
    } else {
      setExpanded(true);
    }

    scrollToTop();
  };

  useEffect(() => {
    if (messages.length === 0) {
      setTabs([]);
      setCurrentTab("home");
      return;
    }

    let medicationsList = [];
    let diseasesList = [];

    messages.forEach(({ message, sender }) => {
      if (!message || sender !== "HealthDesk") return;

      const medicationsFound = medications.filter((s) =>
        message?.toLowerCase().includes(s?.toLowerCase())
      );

      medicationsList = medicationsList.concat(medicationsFound);

      setMedicationsList(medicationsList);

      const diseasesFound = diseases.filter((s) =>
        s.length > 4 ? message?.toLowerCase().includes(s?.toLowerCase()) : message?.includes(s)
      );

      diseasesList = diseasesList.concat(diseasesFound);

      setDiseasesList(diseasesList);

      // Emergency room map triggers
      if (
        !tabs.includes("map") &&
        !tabs.includes("map_urgent") &&
        userData &&
        userData?.role !== "professional"
      ) {
        // Trigger words to open the map
        const emergencyTriggers = [
          "healthcare provider",
          "seek appropriate medical care",
          "Emergency",
          "emergency",
          "911",
        ];

        const urgentTriggers = ["urgent care", "Urgent care", "Urgent Care"];

        const emergencyFound = emergencyTriggers.some((trigger) => message.includes(trigger));
        const urgentFound = urgentTriggers.some((trigger) => message.includes(trigger));

        if (urgentFound) {
          setTabs((tabs) => [...tabs, "map_urgent"]);
          setPreviewTab("map_urgent");
          return;
        }

        if (emergencyFound) {
          setTabs((tabs) => [...tabs, "map"]);
          setPreviewTab("map");
          return;
        }
      }
    });
  }, [messages, userData]);

  useEffect(() => {
    if (tabs.length > 0) setPreviewTab(tabs[tabs.length - 1]);
  }, [tabs]);

  const scrollToTop = () => window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  useEffect(() => {
    let newTabs = [];

    if (medicationsList.length > 0) {
      if (!tabs.includes("medications")) {
        newTabs.push("medications");
      }
    }

    if (diseasesList.length > 0) {
      if (!tabs.includes("diseases")) {
        newTabs.push("diseases");
      }
    }

    if (newTabs.length > 0) setTabs([...tabs].concat(newTabs));
  }, [medicationsList, diseasesList]);

  const getLabel = (tab) => {
    if (tab === "diseases") return "Differential Diagnosis";
    if (tab === "map_urgent") return "Map";

    return tab;
  };

  useEffect(() => {
    // if (expanded) {
    //   setXsSize("calc(100dvh - 80px)");
    //   setSmSize("calc(100dvh - 24px)");
    // } else if (tabs.length === 0) {
    if (tabs.length === 0) {
      setXsSize("0");
      setSmSize("0");
    } else {
      setXsSize("calc(20dvh - 24px)");
      setSmSize("calc(20dvh - 24px)");
    }
  }, [expanded, tabs]);

  return (
    <>
      <Box
        sx={{
          height: {
            xs: xsSize,
            sm: smSize,
          },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
        ref={boxRef}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "calc(100% - 40px)",
          }}
        >
          {expanded ? null : (
            <>
              {/* {expanded ? (
          <Chatbot
            visible={currentTab === "home" && expanded}
            branding={branding}
            user={user}
            userData={userData}
            setCurrentTab={setCurrentTab}
            expanded={expanded}
            boxRef={boxRef}
          />
        ) : null} */}
              {/* Map box */}
              {tabs.includes("map") || tabs.includes("map_urgent") ? (
                <MiniMap
                  visible={isVisible("map") || isVisible("map_urgent")}
                  urgent={tabs.includes("map_urgent")}
                  expanded={expanded}
                />
              ) : null}
              {/* Medications box */}
              {tabs.includes("medications") ? (
                <NewMedicationBox
                  medications={medicationsList}
                  visible={isVisible("medications")}
                  expanded={expanded}
                  openTab={openTab}
                />
              ) : null}
              {/* Diseases box */}
              {tabs.includes("diseases") ? (
                <NewDiseasesBox
                  diseases={diseasesList}
                  visible={isVisible("diseases")}
                  expanded={expanded}
                  openTab={openTab}
                />
              ) : null}
            </>
          )}
        </Box>

        {tabs.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              width: "calc(100% + 50px)",
              paddingTop: "0.5rem",
              overflowX: "auto",
              marginLeft: "-50px",
              marginRight: "-50px",
              paddingLeft: "25px",
              paddingRight: "25px",
              height: 46,
              alignItems: "flex-end",
              borderBottom: 1,
              borderColor: "divider",
            }}
            ref={navRef}
          >
            <Tabs value={currentTab} variant={"scrollable"} onChange={handleChange}>
              <Tab label="SuperIntelligence" value="home" />

              {tabs.map((tab, i) => (
                <Tab key={i} label={getLabel(tab)} value={tab} sx={isHighlighted(tab)} />
              ))}
            </Tabs>

            {/* <Chip
            sx={{ marginLeft: "auto" }}
            color="primary"
            variant="contained"
            icon={expanded ? <ExpandLessRounded /> : <ExpandMoreRounded />}
            label={expanded ? "Show Less" : "Expand"}
            onClick={() => {
              // If we click "show less" and are on the chat tab, redirect to the nearest tab
              if (expanded && currentTab === "home") {
                setCurrentTab(tabs[0]);
              }

              setExpanded(!expanded);
            }}
          /> */}
          </Box>
        ) : null}
      </Box>

      <Box
        sx={{
          height: {
            xs: chatXsSize,
            sm: chatSmSize,
          },
          display: "block",
        }}
      >
        <MainContainer
          style={{
            height: "100%",
            border: "none",
            paddingTop: "1.5rem",
          }}
        >
          <Chatbot
            visible={!expanded}
            branding={branding}
            user={user}
            userData={userData}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            expanded={expanded}
            openTab={openTab}
            tabs={tabs}
            boxRef={boxRef}
          />

          {tabs.includes("map") || tabs.includes("map_urgent") ? (
            <MiniMap
              visible={currentTab === "map" || currentTab === "map_urgent"}
              urgent={tabs.includes("map_urgent")}
              expanded={expanded}
            />
          ) : null}

          {/* Medications box */}
          {tabs.includes("medications") ? (
            <NewMedicationBox
              medications={medicationsList}
              visible={currentTab === "medications"}
              expanded={expanded}
              openTab={openTab}
            />
          ) : null}

          {/* Diseases box */}
          {tabs.includes("diseases") ? (
            <NewDiseasesBox
              diseases={diseasesList}
              visible={currentTab === "diseases"}
              expanded={expanded}
              openTab={openTab}
            />
          ) : null}
        </MainContainer>
      </Box>
    </>
  );
};

export default ContextBox;
