import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { StarRounded } from "@mui/icons-material";

const FilterWrapper = styled.div`
  display: table;
  margin: 0 auto;

  label {
    display: inline;
    margin: 0px 15px 0px 15px;
    align-items: center;
    margin-top: 5px;

    input {
      margin-top: 0;
    }
  }
`;

const Filters = ({ setFilter, filter, vaccine, groups }) => {
  const filters = [
    { value: "all", name: "All" },
    { value: "clinic", name: "Urgent" },
    { value: "emergency", name: "Emergency" },
  ];

  const ratings = [1, 5, 4, 3, 2];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        gap: "0.75rem",
        maxWidth: "500px",
        margin: "auto",
      }}
    >
      <FormControl sx={{ minWidth: 150, width: "100%" }} size="small">
        <InputLabel id="filter-facility-label">Level of Care</InputLabel>
        <Select
          labelId="filter-facility-label"
          id="filter-facility"
          value={filter.facility || ""}
          label="Level of Care"
          onChange={(e) => setFilter({ ...filter, facility: e.target.value })}
        >
          {filters.map(({ value, name }) => (
            <MenuItem key={value} value={value}>
              {name}
            </MenuItem>
          ))}

          {groups?.map(({ group }) => (
            <MenuItem key={group} value={group}>
              {group}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ minWidth: 150, width: "100%" }} size="small">
        <InputLabel id="star-rating-label">Star Rating</InputLabel>
        <Select
          labelId="star-rating-label"
          id="star-rating"
          value={filter.rating || ""}
          label="Star Rating"
          onChange={(e) => setFilter({ ...filter, rating: e.target.value })}
          defaultValue={filter.rating}
          sx={{ maxHeight: "40px" }}
        >
          {ratings.map((value, i) => (
            <MenuItem key={i} value={value}>
              {value === 1 ? (
                "All"
              ) : (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {[...Array(value)].map((s, i) => (
                    <StarRounded
                      key={i}
                      sx={{
                        color: "#FFC404",
                        width: "1.25rem",
                        height: "1.25rem",
                      }}
                    />
                  ))}
                  {value !== 5 && <span style={{ paddingLeft: "3px" }}>+</span>}
                </Box>
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* {vaccine ? null : (
        <>
          {filter === "1" && (
            <Popup>
              <Link to="register">
                Click here to register an Urgent Care Facility.
              </Link>
            </Popup>
          )}

          {filter === "healthcard" && (
            <Popup>
              Safety, savings, and more: The HealthCard lets every healthcare
              visit start on the same page: Yours.{" "}
              <a
                href="https://interlockhealth.com/hclm/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here to learn more.
              </a>
            </Popup>
          )}

          {filter === "videocare" && (
            <Popup>
              Pre(ER): A telehealth connection to the ER
              <br />
              <a
                href="https://interlockhealth.com/vclm/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facilities: Learn how to offer Pre(ER)
              </a>
            </Popup>
          )}
        </>
      )}
  */}
    </Box>
  );
};

export default Filters;
