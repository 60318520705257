import React, { useEffect, useState } from "react";
import firebaseApp, { db } from "../../firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import "firebase/compat/storage";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Alert,
  Link,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ZipTable from "./ZipTable";

import { useStripe } from "@stripe/react-stripe-js";
import usedFreeZip from "components/helpers/usedFreeZip";

const PromoteFacilitiesSettings = ({
  user,
  location,
  switchToAddress,
  switchToBranding,
  open,
  close,
}) => {
  const [loading, setLoading] = useState(true);
  const [addOpen, setAddOpen] = useState(false);
  const [zipsAdded, setZipsAdded] = useState(false);
  const [subLoading, setSubLoading] = useState(true);
  const [subscription, setSubscription] = useState();
  const [zips, setZips] = useState([]);
  const [prices, setPrices] = useState([]);
  const [nearbyZips, setNearbyZips] = useState();

  const getPrices = () => {
    db.collection("plans")
      .where("active", "==", true)
      .where("role", "==", "promote_facilities")
      .get()
      .then((querySnapshot) =>
        querySnapshot.forEach(async (doc) => {
          const productData = doc.data();

          db.collection("plans")
            .doc(doc.id)
            .collection("prices")
            .where("active", "==", true)
            .get()
            .then((priceSnap) => {
              let priceList = [];

              priceSnap.forEach((price) => {
                const priceData = price.data();

                const newPrice = {
                  id: price.id,
                  price: (priceData.unit_amount / 100).toFixed(0),
                  interval: priceData.interval,
                };

                priceList.push(newPrice);
              });

              setPrices(priceList.sort((a, b) => a.price - b.price));
            });
        })
      );
  };

  // Check to see if we have a Stripe subscription yet, if yes, then no need to go through the whole Stripe checkout process again
  const getSubscription = () => {
    if (!user || subscription) return;

    // subscription will be null if not loaded yet
    if (subLoading == true)
      db.collection("users")
        .doc(user.uid)
        .collection("subscriptions")
        .where("role", "==", "promote_facilities")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            setSubscription(doc.data());
          });

          setSubLoading(false);
        });
  };

  const getZips = () => {
    if (!location || !user) return;

    setZips(user.zipAds?.filter((z) => z.status !== "pending") || []);

    // db.collection("zips")
    //   .where("group_users", "array-contains", user.uid)
    //   .get()
    //   .then((querySnapshot) => {
    //     let docs = [];

    //     querySnapshot.forEach(function (doc) {
    //       if (doc.data()) {
    //         const data = doc.data();

    //         docs.push(data);
    //       }
    //     });

    // setZips(docs);
    setLoading(false);
    // });
  };

  useEffect(() => {
    if (open) {
      getZips();
      getPrices();
    }
  }, [open, location, user]);

  // Check if we have a promote_facilities subscription that exists yet
  useEffect(() => {
    if (open) getSubscription();
  }, [open, location, user]);

  return (
    <>
      <Dialog open={open} onClose={close} maxWidth="md" fullWidth>
        <DialogTitle>Promote Your Brand on the HLTHDSK Map</DialogTitle>

        <DialogContent>
          <DialogContentText variant="body2" sx={{ mb: 2 }}>
            Feature your institution's name, logo, tagline, and website on the HLTHDSK map within
            the ZIP codes of your choice. In addition, HLTHDSK will actively promote your presence
            in those selcted areas, ensuring enhanced visibility and engagement with your target
            audience.
          </DialogContentText>

          {usedFreeZip(zips) || !location?.group || !location?.branding ? null : (
            <Alert severity="success" sx={{ mt: 3, mb: 2 }}>
              With your HLTHDSK Plus subscription, you can advertise your facility at a ZIP code of
              your choosing for free for a month. Select your ZIP code below.
            </Alert>
          )}

          {zipsAdded ? (
            <Alert severity="success" sx={{ mt: 3, mb: 4 }}>
              Your advertisements on your selected ZIP codes have been posted.
            </Alert>
          ) : null}

          {!loading && !location?.group ? (
            <Alert severity="error">
              To advertise your facilities, please{" "}
              <Link color="inherit" sx={{ cursor: "pointer" }} onClick={switchToAddress}>
                enter the name of your Facility Group.
              </Link>
            </Alert>
          ) : (
            <ZipTable
              loading={loading}
              subLoading={subLoading}
              subscription={subscription}
              user={user}
              location={location}
              zips={zips}
              setZips={setZips}
              setAddOpen={setAddOpen}
              switchToBranding={switchToBranding}
              prices={prices}
              nearbyZips={nearbyZips}
              setNearbyZips={setNearbyZips}
            />
          )}

          <AddZipCode
            user={user}
            subscription={subscription}
            location={location}
            setZipsAdded={setZipsAdded}
            open={addOpen}
            close={() => setAddOpen(false)}
            prices={prices}
            zips={zips}
            setZips={setZips}
            nearbyZips={nearbyZips}
            setNearbyZips={setNearbyZips}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AddZipCode = ({
  user,
  subscription,
  location,
  open,
  close,
  prices,
  zips,
  setZips,
  setZipsAdded,
  nearbyZips,
  setNearbyZips,
}) => {
  const stripe = useStripe();
  const {
    control,
    handleSubmit,
    formState: { errors },
    unregister,
  } = useForm();
  const [loading, setLoading] = useState(false);

  const [newZips, setNewZips] = useState(1);

  const getZipPrice = (pop) => {
    let zipPrice = "";

    if (pop <= 5000) zipPrice = prices[0];
    else if (pop <= 50000) zipPrice = prices[1];
    else if (pop <= 100000) zipPrice = prices[2];

    if (zipPrice) return zipPrice.price;

    return "";
  };

  const onSubmit = () => {
    const currentZip = open.zip;
    setLoading(true);

    // const slots = Object.keys(submittedZips).length;

    // Get the price base on population
    let price;
    if (open.population <= 5000) price = prices[0];
    else if (open.population <= 50000) price = prices[1];
    else if (open.population <= 100000) price = prices[2];

    if (!usedFreeZip(zips)) {
      const zipToAdd = {
        zip: currentZip,
        zips,
        uid: user.uid,
        group: { name: location.group, ...location.branding },
        branding: location.branding,
        operation: "add",
        freeTrial: true,
      };

      const newZip = { zip: currentZip, status: "active" };
      const newZipsArray = [...zips, newZip];
      const newNearbyZipsArray = nearbyZips.filter((z) => z.zip !== currentZip);

      db.collection("users")
        .doc(user.uid)
        .collection("zipSubs")
        .doc()
        .set(zipToAdd)
        .then(() => {
          setZips(newZipsArray.sort((a, b) => b.zip - a.zip));
          setNearbyZips(newNearbyZipsArray);
          setZipsAdded(true);
          close();
          setLoading(false);
        });

      return;
    }

    // If a stripe subscription already exists, just update the quantity through the backend function
    if (subscription) {
      const zipToAdd = {
        zip: currentZip,
        zips,
        uid: user.uid,
        group: { name: location.group, ...location.branding },
        branding: location.branding,
        price: price.id,
        subId: subscription.items[0].subscription,
        operation: "add",
      };

      const newZip = { zip: currentZip, status: "active" };
      const newZipsArray = [...zips, newZip];
      const newNearbyZipsArray = nearbyZips.filter((z) => z.zip !== currentZip);

      db.collection("users")
        .doc(user.uid)
        .collection("zipSubs")
        .doc()
        .set(zipToAdd)
        .then(() => {
          setZips(newZipsArray.sort((a, b) => b.zip - a.zip));
          setNearbyZips(newNearbyZipsArray);
          setZipsAdded(true);
          close();
          setLoading(false);
        });
    }

    // If there is no subscription yet we have to take the customer to the checkout portal, otherwise just adjust the quantity
    if (!subscription) {
      const checkout = {
        price: price.id,
        // quantity: slots,
        payment_method_collection: "if_required",
        success_url: window.location.origin + "/dashboard/advertising/success", // redirect user to this screen after
        cancel_url: window.location.origin + "/dashboard/advertising",
      };

      const pendingZip = { zip: currentZip, status: "pending" };

      // Add the zip as pending, will update after the subscription has been placed
      // TODO: Fix possibility of multiple pending ZIPs
      db.collection("users")
        .doc(user.uid)
        .collection("zips")
        .doc(currentZip)
        .set(pendingZip)
        .then(() => {
          db.collection("users")
            .doc(user.uid)
            .collection("checkout_sessions")
            .add(checkout)
            .then((docRef) => {
              docRef.onSnapshot((snap) => {
                const { error, sessionId } = snap.data();
                if (error) {
                  alert(`An error occurred: ${error.message}`);
                }

                if (sessionId) {
                  stripe.redirectToCheckout({ sessionId });
                }
              });
            });
        });
    }
    // TODO: Handle errors with catch
  };

  return (
    <Dialog open={!!open} onClose={close}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Add ZIP Code</DialogTitle>

        <DialogContent>
          <DialogContentText variant="body2" sx={{ mb: 2 }}>
            {usedFreeZip(zips)
              ? "Purchase this ZIP code to have your facilities be shown as a category to all users in the area."
              : "Advertise in this ZIP code to have your facilities be shown as a category to all users in the area."}
          </DialogContentText>

          {usedFreeZip(zips) ? (
            <DialogContentText variant="body2" sx={{ mb: 2 }}>
              You will be taken to Stripe to complete your payment.
            </DialogContentText>
          ) : null}

          {/* {Array.from(Array(newZips), (e, i) => {
            const num = i + 1;

            return (
              <Controller
                key={`zip_${num}`}
                name={`zip_${num}`}
                control={control}
                rules={{
                  required: "ZIP code is required.",
                  pattern: {
                    value: /^\d{5}?$/i,
                    message: "Please enter a valid 5-digit ZIP code.",
                  },
                }}
                defaultValue={""}
                render={({ field }) => (
                  <TextField
                    id="zip"
                    label={newZips > 1 ? `ZIP Code #${num}` : `ZIP Code`}
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    fullWidth
                    error={!!errors?.["zip_" + num]}
                    helperText={errors?.["zip_" + num]?.message}
                    sx={{ mt: 2 }}
                    {...field}
                  />
                )}
              />
            );
          })} */}

          {/* {newZips < 10 ? (
            <Box sx={{ mt: 2 }}>
              <Button disabled={loading} onClick={addNewZip} variant="contained" sx={{ mr: 2 }}>
                Add Another ZIP
              </Button>

              {newZips > 1 ? (
                <Button disabled={loading} onClick={removeNewZip} variant="text">
                  Remove ZIP
                </Button>
              ) : null}
            </Box>
          ) : null} */}

          {open ? (
            <Box sx={{ textAlign: "right", mt: 2, mb: 0 }}>
              {usedFreeZip(zips) ? (
                <Typography variant="body">
                  <strong>You will be billed ${getZipPrice(open.population)}.00/month.</strong>
                </Typography>
              ) : null}
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={close}>
            Close
          </Button>
          {!subscription ? (
            <LoadingButton
              loading={loading}
              type="submit"
              disabled={loading}
              autoFocus
              variant="contained"
            >
              {usedFreeZip(zips) ? "Proceed to Payment" : "Claim Your Free ZIP Code"}
            </LoadingButton>
          ) : (
            <LoadingButton
              loading={loading}
              type="submit"
              disabled={loading}
              autoFocus
              variant="contained"
            >
              Add ZIP Codes
            </LoadingButton>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PromoteFacilitiesSettings;
