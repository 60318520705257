export default [
  "Abdominal Hematoma",
  "Abdominal Bruise",
  "Abdominal Contusion",
  "Abdominal Blood Clot",
  "Abdominal Bleeding",
  "Abdominal Hemorrhage",
  "Abdominal trauma",
  "Abdominal injury",
  "Abdominal wound",
  "Injury to abdomen",
  "Trauma to abdomen",
  "Stomach trauma",
  "Stomach injury",
  "Abdomen trauma",
  "Acute adrenal crisis",
  "Acute adrenal failure",
  "Addisonian crisis",
  "Adrenal crisis",
  "Acute Addisonian crisis",
  "Acute adrenal insufficiency",
  "Adrenal failure",
  "Acute angle-closure glaucoma",
  "Acute closed-angle glaucoma",
  "Acute narrow-angle glaucoma",
  "Acute angle-closure crisis",
  "Acute angle-closure attack",
  "Acute angle-closure eye disorder",
  "Acute aortic dissection",
  "Acute aortic syndrome",
  "Dissecting aortic aneurysm",
  "Aortic dissection",
  "DeBakey type I/II/III aortic dissection",
  "Stanford type A/B aortic dissection",
  "Acute aortic regurgitation",
  "Acute aortic stenosis",
  "Severe aortic stenosis",
  "Rapid onset aortic valve stenosis",
  "Acute AS",
  "Acute coronary syndrome : heart attack",
  "unstable angina",
  "ACS : MI",
  "Acute myocardial infarction : heart attack",
  "ST-segment elevation myocardial infarction : STEMI",
  "Non-ST-segment elevation myocardial infarction : NSTEMI",
  "Coronary artery disease : CAD",
  "Coronary thrombosis : heart attack",
  "Cardiac ischemia : heart attack",
  "Myocardial infarction : heart attack",
  "Coronary syndrome : heart attack",
  "angina",
  "Acute Epiglottitis",
  "Acute Supraglottitis",
  "Acute gastroenteritis",
  "Gastrointestinal flu",
  "Bacterial gastroenteritis",
  "Viral gastroenteritis",
  "Stomach flu",
  "Infectious diarrhea",
  "Intestinal infection",
  "Acute Intoxication or Drug Overdose",
  "Acute intoxication",
  "Drug overdose",
  "Alcohol poisoning",
  "Substance overdose",
  "Narcotic overdose",
  "Intoxicant overdose",
  "Drug poisoning",
  "Overuse toxicity",
  "Substance poisoning",
  "Acute kidney injury",
  "AKI",
  "Acute renal failure",
  "ARF",
  "Acute kidney failure",
  "Acute renal injury",
  "Acute Labyrinthitis",
  "Laboratoryyrinthitis",
  "Labyrinthine Inflammation",
  "Inner Ear Infection",
  "Otitis Interna",
  "Acute limb ischemia",
  "Acute peripheral artery occlusion",
  "Acute limb arterial thrombosis",
  "Acute limb embolism",
  "Acute limb arterial occlusion",
  "Acute extremity ischemia",
  "Acute extremity arterial occlusion",
  "Acute liver failure",
  "Fulminant hepatic failure",
  "Rapid hepatic failure",
  "Sudden liver failure",
  "Acute hepatic insufficiency",
  "Fulminant liver insufficiency",
  "Sudden-onset hepatic failure",
  "Hyperacute liver failure",
  "Subacute liver failure",
  "Fulminant liver failure",
  "Acute Liver Injury",
  "Acute Hepatic Injury",
  "Acute Liver Damage",
  "Acute Hepatocellular Injury",
  "Acute Liver Dysfunction",
  "Acute mesenteric ischemia",
  "Acute mesenteric infarction",
  "Acute mesenteric arterial occlusion",
  "Acute mesenteric venous thrombosis",
  "Acute intestinal ischemia",
  "Acute bowel ischemia",
  "Acute myocardial infarction",
  "AMI",
  "Heart attack",
  "Acute coronary syndrome",
  "Myocardial infarction",
  "MI",
  "Acute myocarditis",
  "Acute necrotizing enterocolitis",
  "Acute pancreatitis",
  "Acute pancreatic inflammation",
  "Acute pancreatic necrosis",
  "Acute pancreatic attack",
  "Acute pancreatic edema",
  "Acute porphyria",
  "Acute hepatic porphyria",
  "Acute intermittent porphyria",
  "Acute neuropathic porphyria",
  "Acute psychosis",
  "Acute schizophrenic episode",
  "Acute psychotic disorder",
  "Acute mental disturbance",
  "Acute psychotic break",
  "Acute Respiratory Distress Syndrome",
  "ARDS",
  "Adult Respiratory Distress Syndrome",
  "Acute Respiratory Failure",
  "Shock Lung",
  "Noncardiogenic Pulmonary Edema",
  "Acute Stress Disorder",
  "Acute urinary retention",
  "adrenal crisis",
  "acute adrenal insufficiency",
  "adrenal insufficiency crisis",
  "acute Addison's disease",
  "Agitation",
  "Unrest",
  "Turbulence",
  "Disquiet",
  "Churning",
  "Disruption",
  "Tumult",
  "Stir",
  "Restlessness",
  "Alcohol intoxication or overdose",
  "acute alcohol poisoning",
  "alcohol poisoning",
  "alcohol overdose",
  "ethanol poisoning",
  "ethanol intoxication",
  "Allergic reactions",
  "Allergies",
  "Hypersensitivity reactions",
  "Immunologic reactions",
  "Allergic response",
  "Allergic immune response",
  "Anaphylactic reactions",
  "Allergic hypersensitivity",
  "Allergic reactions to insect stings",
  "Allergy to insect stings",
  "Hypersensitivity to insect stings",
  "Insect sting allergy",
  "Insect sting hypersensitivity",
  "Reaction to insect stings",
  "Sting allergy from insects",
  "Amyloidosis",
  "Anaphylaxis",
  "Anaphylactic shock",
  "Angioedema",
  "Anxiety",
  "Tension",
  "Nervousness",
  "Apprehension",
  "Unease",
  "Dread",
  "Trepidation",
  "Perturbation",
  "Aortic aneurysm",
  "Aneurysm of the aorta",
  "Aortic dilatation",
  "Aortic dilation",
  "Arterial aneurysm",
  "Aneurysm dissection",
  "Dissecting aneurysm",
  "Dissecting hematoma of aorta",
  "Aortic regurgitation",
  "Aortic insufficiency",
  "Aortic valve regurgitation",
  "Aortic valve insufficiency",
  "Aortic stenosis",
  "Aortic valve stenosis",
  "AS",
  "Aplastic anemia",
  "Appendicitis",
  "Arrhythmia",
  "Irregular heartbeat",
  "Cardiac dysrhythmia",
  "Heart arrhythmia",
  "Heart rhythm disorder",
  "Arrhythmogenic right ventricular cardiomyopathy",
  "ARRVC",
  "ARVC",
  "Right ventricular cardiomyopathy",
  "Arrhythmogenic right ventricular dysplasia",
  "ARVD",
  "Arrhythmogenic right ventricular dysplasia/cardiomyopathy",
  "ARVD/C",
  "Ascites",
  "Asthma",
  "Asthma exacerbation",
  "Asthma flare-up",
  "Asthma attack",
  "Asthma episode",
  "Bronchial asthma exacerbation",
  "Acute severe asthma",
  "Asthma crisis",
  "Severe asthma episode",
  "Atherosclerosis",
  "Atrial fibrillation",
  "AF",
  "A-Fib",
  "A Fib",
  "Atrial fib",
  "AFib",
  "Attention deficit hyperactivity disorder",
  "ADHD",
  "Autism spectrum disorder",
  "Autism",
  "ASD",
  "Autistic disorder",
  "Pervasive developmental disorder",
  "PDD",
  "Autoimmune encephalitis",
  "Autoimmune brain inflammation",
  "Autoimmune hepatitis",
  "Back pain",
  "Backache",
  "Lower back pain",
  "Lumbago",
  "Spinal pain",
  "Dorsalgia",
  "Bacterial pneumonia",
  "Pneumonia due to bacteria",
  "Bacterial infection of the lungs",
  "Lung bacterial infection",
  "Bacterial-induced pneumonia",
  "Basal cell carcinoma",
  "BCC",
  "Basal-cell cancer",
  "Basal cell skin cancer",
  "Basal-cell carcinoma",
  "Behçet's disease",
  "Behcet's syndrome",
  "Adamantiades-Behçet's disease",
  "Silk Road disease",
  "Bell's Palsy",
  "Facial Nerve Paralysis",
  "Facial Paralysis",
  "Idiopathic Facial Paralysis",
  "Acute Peripheral Facial Palsy",
  "Benign paroxysmal positional vertigo",
  "BPPV",
  "Benign prostatic hyperplasia",
  "BPH",
  "Benign prostatic hypertrophy",
  "Benign enlargement of the prostate",
  "Benign prostatic obstruction",
  "Biliary colic",
  "Gallstone pain",
  "Gallbladder attack",
  "Biliary pain",
  "Ductal colic",
  "Biliary Obstruction",
  "Bile Duct Blockage",
  "Biliary Atresia",
  "Biliary Stenosis",
  "Cholestasis",
  "Bile Flow Obstruction",
  "Blocked Bile Duct",
  "Bipolar disorder",
  "bipolar affective disorder",
  "manic-depressive illness",
  "manic depression",
  "Bladder cancer",
  "Urothelial carcinoma",
  "Transitional cell carcinoma",
  "Bladder infection",
  "Urinary tract infection",
  "Cystitis",
  "Blepharospasm",
  "BEB",
  "Benign Essential Blepharospasm",
  "Essential Blepharospasm",
  "Eyelid Spasm",
  "Bone cancer",
  "Bone neoplasm",
  "Osteosarcoma",
  "Chondrosarcoma",
  "Ewing sarcoma",
  "Bone malignancy",
  "Bone tumor",
  "Borderline personality disorder",
  "Bowel obstruction",
  "Intestinal blockage",
  "Intestinal obstruction",
  "Bowel blockage",
  "Bowel Obstruction",
  "Intestinal Obstruction",
  "Bowel Blockage",
  "Intestinal Blockage",
  "Brain aneurysm",
  "Intracranial aneurysm",
  "Cerebral aneurysm",
  "Brain tumor",
  "Intracranial neoplasm",
  "Cerebral tumor",
  "Intracranial tumor",
  "Brain neoplasm",
  "Cerebral neoplasm",
  "Brain tumor recurrence",
  "Brainstem stroke",
  "Brain stem infarction",
  "Medullary stroke",
  "Pontine stroke",
  "Mesencephalic stroke",
  "Brainstem CVA",
  "Brainstem cerebrovascular accident",
  "Breast cancer",
  "Breast fibroadenoma",
  "breast adenofibroma",
  "fibroadenoma of the breast",
  "breast fibroepithelial tumor",
  "fibroepithelial lesion of the breast",
  "Bronchial asthma",
  "Asthmatic bronchitis",
  "Chronic asthma",
  "Reactive airway disease",
  "Bronchiectasis",
  "Bronchiolitis",
  "Bullimia",
  "Bullimia Nervosa",
  "Bursitis",
  "Candidiasis",
  "Candida infection",
  "Thrush",
  "Moniliasis",
  "Yeast infection",
  "Carbon monoxide poisoning",
  "carbon monoxide intoxication",
  "CO poisoning",
  "carbon monoxide \\(CO\\) toxicity",
  "carbon monoxide gas poisoning",
  "carbon monoxide inhalation poisoning",
  "carbon monoxide exposure",
  "Cardiac arrest",
  "sudden cardiac arrest",
  "heart arrest",
  "myocardial arrest",
  "circulatory arrest",
  "asystole",
  "Cardiac arrhythmias",
  "Arrhythmias",
  "Cardiac dysrhythmias",
  "Heart arrhythmias",
  "Irregular heartbeats",
  "Heart rhythm disorders",
  "Cardiac tamponade",
  "Pericardial tamponade",
  "Carpal tunnel syndrome",
  "Median neuropathy at the wrist",
  "CTS",
  "Wrist median entrapment neuropathy",
  "Wrist canal syndrome",
  "Cataracts",
  "Opacities",
  "Lens clouding",
  "Opaque lenses",
  "Crystalline opacities",
  "Celiac disease",
  "coeliac disease",
  "celiac sprue",
  "gluten-sensitive enteropathy",
  "Cellulitis",
  "Cellulites",
  "Cervical cancer",
  "Cervix uteri carcinoma",
  "Cervical carcinoma",
  "Cervical neoplasm",
  "Cancer of the cervix",
  "Carcinoma of the cervix",
  "Neoplasms of uterine cervix",
  "Malignant neoplasm of cervix",
  "Malignant tumor of cervix",
  "Cervical dysplasia",
  "cervical intraepithelial neoplasia",
  "CIN",
  "cervical squamous intraepithelial lesion",
  "CSIL",
  "Cervical spondylosis",
  "Cervical osteoarthritis",
  "Cervical degenerative disc disease",
  "Cervical arthritis",
  "Neck arthritis",
  "Spondylosis of the neck",
  "Cervical spine arthritis",
  "Chronic neck pain due to degeneration",
  "Cervicitis",
  "Chemical burns",
  "Chemical injury",
  "Chemical burn",
  "Caustic burn",
  "Caustic injury",
  "Chest trauma",
  "Thoracic injury",
  "Chest injury",
  "Thoracic trauma",
  "Blunt chest trauma",
  "Penetrating chest trauma",
  "Rib fracture",
  "Thoracic contusion",
  "Pulmonary contusion",
  "Hemothorax",
  "Pneumothorax",
  "Chiari malformation",
  "Arnold-Chiari malformation",
  "Chiari type I malformation",
  "Chiari type II malformation",
  "Chiari type III malformation",
  "Chiari type IV malformation",
  "Chickenpox",
  "Varicella",
  "Varicella Zoster",
  "Cholecystitis",
  "Inflamed gallbladder",
  "Gallbladder inflammation",
  "Cholelithiasis",
  "gallstones",
  "calculi biliares",
  "cholesterol stones",
  "pigment stones",
  "Cholera",
  "Chronic bronchitis",
  "Chronic Obstructive Bronchitis",
  "Chronic Inflammatory Bronchitis",
  "Chronic Productive Cough",
  "Bronchitis Chronica",
  "Chronic fatigue syndrome",
  "myalgic encephalomyelitis",
  "ME/CFS",
  "systemic exertion intolerance disease",
  "SEID",
  "Chronic inflammatory demyelinating polyneuropathy",
  "Chronic inflammatory demyelinating polyradiculoneuropathy",
  "CIDP",
  "Chronic relapsing polyneuropathy",
  "Chronic kidney disease",
  "Chronic renal disease",
  "Chronic renal failure",
  "Chronic kidney failure",
  "CKD",
  "CRD",
  "Chronic migraines",
  "Recurrent migraines",
  "Persistent migraines",
  "Frequent migraines",
  "Continual migraines",
  "Long-term migraines",
  "Ongoing migraines",
  "Chronic obstructive pulmonary disease",
  "COPD",
  "Chronic obstructive airway disease",
  "Chronic obstructive lung disease",
  "Chronic airflow limitation",
  "Chronic bronchitis and emphysema",
  "Chronic obstructive pulmonary disease exacerbation",
  "Acute exacerbation of chronic obstructive pulmonary disease",
  "COPD exacerbation",
  "Chronic obstructive lung disease exacerbation",
  "Exacerbation of COPD",
  "Exacerbation of chronic obstructive pulmonary disease",
  "Acute COPD exacerbation",
  "AE-COPD",
  "Chronic Pain",
  "Chronic Discomfort",
  "Persistent Pain",
  "Long-term Pain",
  "Enduring Pain",
  "Continual Pain",
  "Chronic Soreness",
  "Lasting Pain",
  "Chronic Ache",
  "Chronic sinusitis",
  "Chronic rhinosinusitis",
  "Chronic sinus infection",
  "Circadian Rhythm Disorders",
  "Circadian Rhythm Sleep Disorders",
  "Circadian Rhythm Sleep-Wake Disorders",
  "Circadian Rhythm Dysregulation",
  "Circadian Rhythm Disorder",
  "Circadian Sleep Disorders",
  "Circadian Dysrhythmia",
  "Circadian Rhythm Disruptions",
  "Circadian Rhythm Imbalance",
  "Circadian Timing Disorders",
  "Cirrhosis",
  "hepatic cirrhosis",
  "liver cirrhosis",
  "Cluster headache",
  "Colitis",
  "Inflammation of the Colon",
  "Colon cancer",
  "Colorectal cancer",
  "Bowel cancer",
  "Large bowel cancer",
  "Colonic carcinoma",
  "Rectal cancer",
  "Colon carcinoma",
  "Rectal carcinoma",
  "Large intestine cancer",
  "Common cold",
  "Head cold",
  "Nasopharyngitis",
  "Rhinopharyngitis",
  "Acute viral rhinopharyngitis",
  "Compartment syndrome",
  "Compartment syndromes",
  "Compartmental syndrome",
  "Compartmental syndromes",
  "Complete heart block",
  "Third-degree AV block",
  "Third-degree atrioventricular block",
  "CHB",
  "Complex regional pain syndrome",
  "Reflex sympathetic dystrophy",
  "RSD",
  "Causalgia",
  "Congenital heart defect",
  "Congenital cardiac anomaly",
  "Congenital heart disease",
  "CHD",
  "Congenital cardiovascular malformation",
  "Congestive heart failure",
  "Heart failure",
  "CHF",
  "Cardiac failure",
  "Left-sided heart failure",
  "Right-sided heart failure",
  "Conjunctivitis",
  "Pink eye",
  "Eye inflammation",
  "Eye infection",
  "Ophthalmia",
  "Constipation",
  "Obstipation",
  "Costiveness",
  "Bowel irregularity",
  "Sluggish bowels",
  "Delayed defecation",
  "Hard stools",
  "Incomplete evacuation",
  "Slow bowel movement",
  "Coronary artery disease",
  "Coronary heart disease",
  "Ischemic heart disease",
  "Atherosclerotic heart disease",
  "Creutzfeldt-Jakob disease",
  "CJD",
  "Crohn's disease",
  "Regional enteritis",
  "Granulomatous enteritis",
  "Terminal ileitis",
  "Regional ileitis",
  "Croup",
  "Cushing syndrome",
  "Cushing's syndrome",
  "Hypercortisolism",
  "Hypercorticism",
  "Cutaneous infections with signs of spreading",
  "Cutaneous infections with signs of dissemination",
  "Cutaneous infections with systemic involvement",
  "Spreading skin infections",
  "Disseminated cutaneous infections",
  "Expanding skin infections",
  "Systemic cutaneous infections",
  "Cutaneous lupus erythematosus",
  "Deep vein thrombosis",
  "DVT",
  "Venous thrombosis",
  "Venous thromboembolism",
  "Phlebothrombosis",
  "Dehydration",
  "Desiccation",
  "Drying",
  "Exsiccation",
  "Delirium",
  "Confusion",
  "Madness",
  "Frenzy",
  "Hysteria",
  "Lunacy",
  "Insanity",
  "Dementia",
  "Derangement",
  "Hallucinations",
  "Delirium tremens",
  "DTs",
  "Alzheimer's disease",
  "Cognitive decline",
  "Senile dementia",
  "Chronic brain syndrome",
  "Organic brain syndrome",
  "Benign senescent forgetfulness",
  "Vascular dementia",
  "Dementia exacerbation",
  "Dengue Fever",
  "Dengue",
  "Hemorrhagic Fever",
  "Dandy Fever",
  "Breakbone Fever",
  "Dental caries",
  "Cavities",
  "Tooth decay",
  "Carious lesions",
  "Cariogenesis",
  "Decayed teeth",
  "Depression",
  "Sadness",
  "Despondency",
  "Melancholy",
  "Gloom",
  "Despair",
  "Dejection",
  "Sorrow",
  "Unhappiness",
  "Grief",
  "Diabetes insipidus",
  "Diabetes mellitus",
  "Diabetic ketoacidosis",
  "Diabetic acidosis",
  "DKA",
  "Ketoacidosis due to diabetes",
  "Diabetic retinopathy",
  "Difficulty with fine motor skills",
  "Impaired hand dexterity",
  "Reduced finger coordination",
  "Poor manual dexterity",
  "Decreased fine motor control",
  "Challenges with small muscle movements",
  "Diminished fine motor function",
  "Weakened hand coordination",
  "Substandard fine motor skills",
  "Dilated cardiomyopathy",
  "Cardiomyopathy",
  "Dilated",
  "Primary dilated cardiomyopathy",
  "Congestive dilated cardiomyopathy",
  "Diphtheria",
  "Disseminated herpes simplex infection",
  "Disseminated HSV infection",
  "Disseminated herpes simplex disease",
  "Systemic herpes simplex infection",
  "Generalized herpes simplex infection",
  "Disseminated intravascular coagulation",
  "Disseminated intravascular coagulopathy",
  "Consumptive coagulopathy",
  "DIC",
  "Diverticulitis",
  "Drowning",
  "Drug eruptions",
  "Drug rash",
  "Medication-induced rash",
  "Medication reaction",
  "Drug hypersensitivity",
  "Pharmaceutical rash",
  "Drug-induced dermatitis",
  "Medicament eruption",
  "Drug-induced rash",
  "Drug-induced skin reaction",
  "Drug toxicity",
  "Overdose",
  "Medication overdose",
  "Intoxication with drugs",
  "Pharmaceutical overdose",
  "Drug intoxication",
  "Drug overconsumption",
  "Drug overuse",
  "Accidental overdose",
  "Intentional overdose",
  "Acute drug overdose",
  "Severe drug overdose",
  "Dry eye syndrome",
  "Keratoconjunctivitis sicca",
  "Keratitis sicca",
  "Dry eye disease",
  "DED",
  "Evaporative dry eye",
  "Chronic dry eye",
  "Chronic dry eye syndrome",
  "Ocular surface disease",
  "Dupuytren's contracture",
  "Peyronie's disease",
  "Palmar fibromatosis",
  "Contraction of palmar fascia",
  "Tenosynovitis of the palmar aponeurosis",
  "Dysmenorrhea",
  "Menstrual cramps",
  "Painful menstruation",
  "Menstrual pain",
  "Period pain",
  "Dyspareunia",
  "Painful intercourse",
  "Coital pain",
  "Painful coitus",
  "Sexual pain",
  "Intercourse pain",
  "Dyspepsia",
  "Indigestion",
  "Upset stomach",
  "Stomach upset",
  "Dysphagia",
  "swallowing difficulty",
  "impairment in swallowing",
  "swallowing disorder",
  "swallowing impairment",
  "Dysuria",
  "Ear infection",
  "Otitis media",
  "Middle ear infection",
  "Inner ear infection",
  "Ear inflammation",
  "AOM \\(acute otitis media\\)",
  "OME \\(otitis media with effusion\\)",
  "Ear disease",
  "Eating disorders",
  "Anorexia nervosa",
  "Bulimia nervosa",
  "Binge-eating disorder",
  "Feeding disorders",
  "Ebola Virus Disease",
  "Ebola Hemorrhagic Fever",
  "EVD",
  "Eclampsia",
  "Ectopic Pregnancy",
  "Eccyesis",
  "Tubal Pregnancy",
  "Extrauterine Pregnancy",
  "Eczema",
  "Dermatitis",
  "Elder abuse",
  "Senior abuse",
  "Elder mistreatment",
  "Elder neglect",
  "Elder exploitation",
  "Abuse of older adults",
  "Maltreatment of elders",
  "Abuse of the elderly",
  "Senior citizen abuse",
  "Geriatric abuse",
  "Electric shock",
  "electrocution",
  "shock",
  "shock therapy",
  "shock treatment",
  "current shock",
  "electric current injury",
  "high-voltage shock",
  "power surge shock",
  "static shock",
  "Encephalitis",
  "Endocarditis",
  "Infective endocarditis",
  "Bacterial endocarditis",
  "Mycotic endocarditis",
  "Nonbacterial thrombotic endocarditis",
  "Libman-Sacks endocarditis",
  "Endometriosis",
  "Epilepsy",
  "Erectile dysfunction",
  "Impotence",
  "Sexual dysfunction",
  "Phallic failure",
  "Male impotence",
  "Penile erectile dysfunction",
  "Esophageal cancer",
  "Esophageal carcinoma",
  "Esophagus cancer",
  "Esophagus carcinoma",
  "Essential tremor",
  "ET",
  "Familial tremor",
  "Benign essential tremor",
  "Hereditary tremor",
  "Ewing's sarcoma",
  "Peripheral primitive neuroectodermal tumor",
  "Askin tumor",
  "Pnet",
  "Eye floaters",
  "floaters",
  "vitreous floaters",
  "intravitreal floaters",
  "muscae volitantes",
  "Eyelid Myokymia",
  "Facial Bone Fracture",
  "Facial Bone Break",
  "Facial Bone Crack",
  "Facial Skeleton Fracture",
  "Fractured Facial Bone",
  "Facial Nerve Compression or Injury",
  "Facial Nerve Entrapment",
  "Facial Nerve Damage",
  "Facial Nerve Trauma",
  "Facial Nerve Impingement",
  "Falls",
  "Waterfalls",
  "Cascades",
  "Rapids",
  "Torrents",
  "Febrile seizures",
  "Febrile convulsions",
  "Febrile fits",
  "Febrile spasms",
  "Pediatric febrile seizures",
  "Febrile epileptic seizures",
  "Childhood febrile seizures",
  "Female infertility",
  "infertility in women",
  "women's infertility",
  "female sterility",
  "women's sterility",
  "Fibroadenosis",
  "Fibrocystic Breast Disease",
  "Fibrocystic Changes",
  "Fibrocystic Mastopathy",
  "Fibrocystic Breast Condition",
  "Fibrocystic breast changes",
  "Fibrocystic breasts",
  "Fibrocystic breast disease",
  "Benign breast disease",
  "Breast cysts",
  "Mammary dysplasia",
  "Chronic cystic mastitis",
  "Fibroids",
  "Leiomyomas",
  "Myomas",
  "Uterine fibroids",
  "Fibromyalgia",
  "Fibromyositis",
  "Fibrositis",
  "Flatulence",
  "Gas",
  "Bloating",
  "Flatus",
  "Wind",
  "Food poisoning",
  "Foodborne illness",
  "Foodborne disease",
  "Foodborne infection",
  "FBD",
  "Food illness",
  "Food infection",
  "Foreign body aspiration",
  "Airway foreign body",
  "Aspirated foreign body",
  "Inhaled foreign body",
  "Tracheobronchial foreign body",
  "Bronchial foreign body",
  "Lodged foreign object in airway",
  "Foreign Body Obstruction",
  "Airway Obstruction",
  "Airway Blockage",
  "Airway Foreign Body",
  "Airway Obstruction by Foreign Body",
  "Blocked Airway",
  "Blocked Trachea",
  "Foreign Body Blocking Airway",
  "Foreign Object in Airway",
  "Obstruction of Airway",
  "Fractures",
  "breaks",
  "cracks",
  "fissures",
  "splits",
  "rifts",
  "clefts",
  "ruptures",
  "Fungal infection",
  "Mycosis",
  "Fungal disease",
  "Fungus infection",
  "Fungus disease",
  "Gallbladder cancer",
  "Carcinoma of the gallbladder",
  "Gallbladder carcinoma",
  "Gallbladder Disease",
  "Biliary Disease",
  "Cholecystopathy",
  "Gallbladder Disorder",
  "Cholecystic Disease",
  "Gallbladder Condition",
  "Gallstones",
  "Choleliths",
  "Biliary calculi",
  "Biliary stones",
  "Cholecystolithiasis",
  "Gastric cancer",
  "Stomach cancer",
  "Adenocarcinoma of the stomach",
  "Gastric carcinoma",
  "Gastric adenocarcinoma",
  "Gastritis",
  "Gastric inflammation",
  "Stomach inflammation",
  "Gastroenteritis",
  "Gastric flu",
  "Stomach virus",
  "Intestinal flu",
  "GI bug",
  "Gastrointestinal infection",
  "Gastroesophageal reflux disease",
  "GERD",
  "Acid reflux",
  "Reflux esophagitis",
  "Gastro-oesophageal reflux disease",
  "GORD",
  "Gastrointestinal bleeding",
  "Gastrointestinal hemorrhage",
  "Gastrointestinal bleed",
  "GI bleeding",
  "GI hemorrhage",
  "GI bleed",
  "Bleeding in the gastrointestinal tract",
  "GI tract hemorrhage",
  "Severe GI bleeding",
  "Severe gastrointestinal hemorrhage",
  "Intestinal bleeding",
  "Severe intestinal hemorrhage",
  "Major gastrointestinal bleeding",
  "Gastrointestinal stromal tumor",
  "GIST",
  "Gastrointestinal stromal neoplasm",
  "Gastrointestinal stromal sarcoma",
  "Generalized anxiety disorder",
  "Chronic anxiety disorder",
  "GAD",
  "Genital herpes",
  "Herpes genitalis",
  "Herpes simplex virus type 2",
  "HSV-2",
  "Genital HSV",
  "Genital warts",
  "HPV warts",
  "Anogenital warts",
  "Condylomata acuminata",
  "Venereal warts",
  "acid reflux",
  "gastroesophageal reflux disease",
  "reflux",
  "Gestational diabetes",
  "Gestational trophoblastic disease",
  "hydatidiform mole",
  "Gestational trophoblastic neoplasia",
  "Choriocarcinoma",
  "Placental site trophoblastic tumor",
  "Epithelioid trophoblastic tumor",
  "Gingivitis",
  "Periodontal Disease",
  "Gum Inflammation",
  "Gum Disease",
  "Periodontitis",
  "Glaucoma",
  "Goiter",
  "Struma",
  "Thyromegaly",
  "Gout",
  "Graves' disease",
  "Basedow's disease",
  "Diffuse toxic goiter",
  "Flajani-Basedow-Graves disease",
  "Parry's disease",
  "Guillain-barré syndrome",
  "Acute inflammatory demyelinating polyneuropathy",
  "GBS",
  "Landry's paralysis",
  "Hand, foot, and mouth disease",
  "Hashimoto's thyroiditis",
  "Chronic lymphocytic thyroiditis",
  "Autoimmune thyroiditis",
  "Hashimoto disease",
  "Head injury with signs of concussion or severe neurological deficits",
  "Traumatic brain injury with symptoms of concussion",
  "Head trauma with signs of serious neurological impairment",
  "Concussive head injury with severe symptoms",
  "Severe head injury with neurological deficits",
  "Brain injury with concussion symptoms",
  "Head injury leading to significant neurological issues",
  "Head injury presenting severe neurological symptoms",
  "Serious head injury with concussion signs",
  "Significant brain trauma with concussion",
  "Head trauma",
  "Head injury",
  "Brain injury",
  "Craniocerebral trauma",
  "Head wound",
  "Cerebral trauma",
  "Traumatic brain injury",
  "Concussion",
  "Blow to the head",
  "Head concussion",
  "Hearing loss",
  "Deafness",
  "Hard of hearing",
  "Hearing impairment",
  "Sensory neural hearing loss",
  "Audiological impairment",
  "Partial deafness",
  "Diminished hearing",
  "Conductive hearing loss",
  "Impaired hearing",
  "Coronary thrombosis",
  "Heart insufficiency",
  "Myocardial failure",
  "Heart failure exacerbation",
  "CHF exacerbation",
  "Congestive heart failure exacerbation",
  "Acute heart failure exacerbation",
  "Decompensated heart failure",
  "Heat stroke",
  "Heatstroke",
  "Sunstroke",
  "Solar Plexus",
  "Siriasis",
  "Hemifacial Spasm",
  "Hemochromatosis",
  "Iron overload disorder",
  "Hemolytic anemia",
  "Autoimmune hemolytic anemia",
  "Acquired hemolytic anemia",
  "Congenital hemolytic anemia",
  "Erythroblastosis fetalis",
  "Hereditary spherocytosis",
  "G6PD deficiency hemolytic anemia",
  "Paroxysmal nocturnal hemoglobinuria",
  "Thalassemia",
  "Cold agglutinin disease",
  "Hemophilia",
  "Hemorrhagic shock",
  "Exsanguination",
  "Hypovolemic shock due to hemorrhage",
  "Bleeding shock",
  "Blood loss shock",
  "Hemorrhoids",
  "Piles",
  "Hepatic encephalopathy",
  "Liver encephalopathy",
  "Portosystemic encephalopathy",
  "Hepatic coma",
  "PSE",
  "Hepatitis",
  "Hereditary angioedema",
  "HAE",
  "Herniated disc",
  "Herniated disk",
  "Slipped disc",
  "Slipped disk",
  "Ruptured disc",
  "Ruptured disk",
  "Bulging disc",
  "Bulging disk",
  "Prolapsed disc",
  "Prolapsed disk",
  "Disc herniation",
  "Disk herniation",
  "Herpangina",
  "Herpes zoster",
  "Shingles",
  "Zona",
  "Zoster",
  "Hidradenitis suppurativa",
  "Acne inversa",
  "Verneuil's disease",
  "Apocrine acne",
  "Pyodermia fistulans sinifica",
  "High blood pressure",
  "Hypertension",
  "Elevated blood pressure",
  "Arterial hypertension",
  "High arterial pressure",
  "Hip bursitis",
  "Inflammation of the hip bursa",
  "Trochanteric bursitis",
  "Ischial bursitis",
  "Iliopsoas bursitis",
  "Hip fracture",
  "Fractured hip",
  "Hip break",
  "Broken hip",
  "Hirsutism",
  "Hirsutismus",
  "Hiv/aids",
  "HIV/AIDS",
  "HIV-AIDS",
  "Human Immunodeficiency Virus/Acquired Immunodeficiency Syndrome",
  "Hodgkin lymphoma",
  "Hodgkin's lymphoma",
  "Hodgkin disease",
  "Hodgkin's disease",
  "Homicidal ideation",
  "Huntington's disease",
  "Huntington's chorea",
  "Huntington's disorder",
  "Huntington's syndrome",
  "Hydrocephalus",
  "Water on the brain",
  "Hydrocephaly",
  "Hypercalcemia",
  "Hyperglycemia",
  "High blood sugar",
  "Hyperglycemia condition",
  "Elevated blood glucose",
  "High glucose levels",
  "Hyperglycemic crisis",
  "Hyperglycemic hyperosmolar state",
  "Hyperosmolar hyperglycemic state",
  "Hyperosmolar non-ketotic coma",
  "Hyperosmolar nonketotic state",
  "Hyperosmolar non-ketotic hyperglycemia",
  "Nonketotic hyperglycemic hyperosmolar coma",
  "Nonketotic hyperosmolar syndrome",
  "Hyperosmolar non-ketotic hyperosmolar syndrome",
  "Hyperkalemia",
  "Kaliemia",
  "Hyperpotassemia",
  "Hypernatremia",
  "Severe Hypernatremia",
  "Acute Hypernatremia",
  "Critical Hypernatremia",
  "Extreme Hypernatremia",
  "Hyperparathyroidism",
  "Hypertensive emergency",
  "Hypertensive crisis",
  "Malignant hypertension",
  "Acute hypertensive crisis",
  "Accelerated hypertension",
  "Hyperthermia",
  "Pyrexia",
  "Fever",
  "High temperature",
  "Elevated temperature",
  "Hyperthyroidism",
  "Thyrotoxicosis",
  "Hypocalcemia",
  "Hypocalcaemia",
  "Calcium deficiency",
  "Low calcium",
  "Decreased calcium levels",
  "Hypoglycemia",
  "Low blood sugar",
  "Insulin reaction",
  "Insulin shock",
  "Hypokalemia",
  "Kaliopenia",
  "Potasemia",
  "Potassium deficiency",
  "Hyponatremia",
  "hypo-osmolality",
  "low blood sodium",
  "Severe Hyponatremia",
  "Acute Hyponatremia",
  "Critical Hyponatremia",
  "Life-threatening Hyponatremia",
  "Hypoparathyroidism",
  "Hypothermia",
  "Hypothermy",
  "Hypo-thermal condition",
  "Hypothyroidism",
  "Underactive thyroid",
  "Hypovolemia",
  "Volume Depletion",
  "Fluid Volume Deficit",
  "Intravascular Volume Depletion",
  "Blood Volume Loss",
  "Hypovolemic State",
  "Reduced Blood Volume",
  "Decreased Blood Volume",
  "Hypovolemic shock",
  "Hypovolemic Shock",
  "Hypoxic-ischemic encephalopathy",
  "Perinatal asphyxia encephalopathy",
  "Hypoxic-ischemic brain injury",
  "Birth asphyxia encephalopathy",
  "Neonatal encephalopathy from hypoxia-ischemia",
  "Hypoxic brain injury",
  "HIE",
  "Hysterectomy",
  "Ichthyosis",
  "Idiopathic Guttate Hypomelanosis",
  "IGH",
  "Impetigo",
  "Impetigo Contagiosa",
  "Increased intracranial pressure",
  "Cerebral hypertension",
  "Intracranial hypertension",
  "Intracranial hyperpressure",
  "Elevated intracranial pressure",
  "Infectious Mononucleosis",
  "Mono",
  "Mononucleosis",
  "Glandular Fever",
  "Inflammatory bowel disease",
  "Ulcerative colitis",
  "Inflammatory Process in the Pharynx or Esophagus",
  "Influenza",
  "flu",
  "Inguinal hernia",
  "Groin hernia",
  "Indirect hernia",
  "Direct hernia",
  "Inguinal rupture",
  "Insomnia",
  "Sleeplessness",
  "Wakefulness",
  "Internal bleeding",
  "Internal hemorrhage",
  "Internal haemorrhage",
  "Intra-abdominal bleeding",
  "Intra-abdominal hemorrhage",
  "Internal blood loss",
  "Internal hemorrhaging",
  "Internal haemorrhaging",
  "Interstitial cystitis",
  "IC",
  "BPBS",
  "Bladder Pain Syndrome",
  "Chronic Pelvic Pain",
  "Chronic Bladder Pain",
  "Frequency-Urgency-Dysuria Syndrome",
  "Interstitial lung disease",
  "Interstitial pneumonitis",
  "Pulmonary fibrosis",
  "Lung fibrosis",
  "Lung interstitial disease",
  "Gut obstruction",
  "Gastrointestinal obstruction",
  "GI obstruction",
  "Enteric obstruction",
  "Intoxication",
  "inebriation",
  "drunkenness",
  "stupefaction",
  "inebriety",
  "exhilaration",
  "Intracranial hemorrhage",
  "Intracerebral hemorrhage",
  "Cerebral hemorrhage",
  "Brain bleed",
  "Brain hemorrhage",
  "Hemorrhagic stroke",
  "Intraventricular hemorrhage",
  "IVH",
  "Intraventricular bleed",
  "Invasive fungal infection",
  "invasive mycosis",
  "invasive fungal disease",
  "invasive fungal infections",
  "Iron-deficiency anemia",
  "Irritable bowel syndrome",
  "IBS",
  "spastic colon",
  "functional bowel disorder",
  "irritable colon",
  "mucous colitis",
  "spastic bowel",
  "Kawasaki disease",
  "Kawasaki syndrome",
  "Mucocutaneous lymph node syndrome",
  "Kidney cancer",
  "Renal cancer",
  "Renal cell carcinoma",
  "Hypernephroma",
  "Renal adenocarcinoma",
  "Kidney infection",
  "Pyelonephritis",
  "Renal infection",
  "Upper urinary tract infection",
  "UTI - kidney",
  "Renal parenchymal infection",
  "Renal pyelonephritis",
  "Infectious tubulointerstitial nephritis",
  "Kidney Stones",
  "Renal Calculi",
  "Nephrolithiasis",
  "Renal Stones",
  "Kidney Calculi",
  "Labyrinthitis",
  "Lacerations",
  "Lesions",
  "Cuts",
  "Slashes",
  "Gashes",
  "Lactose intolerance",
  "Lactase deficiency",
  "Lactose malabsorption",
  "Lactose non-persistence",
  "Lactose indigestion",
  "Laryngitis",
  "Lead poisoning",
  "plumbism",
  "saturnism",
  "Leukemia",
  "Leukopenia",
  "Leucopenia",
  "Lichen Sclerosus",
  "Liver Abscess",
  "Hepatic Abscess",
  "Liver cancer",
  "Hepatic cancer",
  "Primary liver cancer",
  "Hepatocellular carcinoma",
  "Malignant hepatoma",
  "Liver cirrhosis",
  "Cirrhosis of the liver",
  "Hepatic cirrhosis",
  "Local anesthetic systemic toxicity",
  "LAST",
  "Local anesthetic toxicity",
  "Systemic toxicity of local anesthetics",
  "Anesthetic toxicity",
  "Local anesthetic overdose",
  "LA systemic toxicity",
  "Anesthetic systemic toxicity",
  "Local Anesthetic Systemic Toxicity \\(LAST\\)",
  "Local anesthetic-related systemic toxicity",
  "Low back pain",
  "Lumbar pain",
  "Lower spinal pain",
  "Low blood pressure",
  "Hypotension",
  "Lung cancer",
  "Carcinoma of lung",
  "Pulmonary carcinoma",
  "Lung carcinoma",
  "Lupus",
  "Lyme disease",
  "lyme borreliosis",
  "Lymphedema",
  "Lymphoedema",
  "Lymphatic Swelling",
  "Lymphatic Obstruction",
  "Swelling Disorder",
  "Edema of lymph",
  "Chronic Lymphedema",
  "Macular degeneration",
  "Age-related macular degeneration",
  "AMD",
  "ARMD",
  "Maculopathy",
  "Malaria",
  "Malignant Hyperthermia",
  "Malignant otitis externa",
  "Skull base osteomyelitis",
  "Necrotizing external otitis",
  "Malnutrition",
  "undernutrition",
  "insufficient nutrition",
  "nutritional deficiency",
  "nutrient deficiency",
  "dietary deficiency",
  "starvation",
  "Mania/Hypomania",
  "excitement",
  "enthusiasm",
  "frenzy",
  "hyperactivity",
  "agitation",
  "delirium",
  "euphoria",
  "overexcitement",
  "Massive hemoptysis",
  "Severe pulmonary bleeding",
  "Major hemoptysis",
  "Profuse hemoptysis",
  "Life-threatening hemoptysis",
  "Extensive lung hemorrhage",
  "Measles",
  "Rubeola",
  "Morbilli",
  "Melanoma",
  "Meniere's disease",
  "Endolymphatic hydrops",
  "Labyrinthine hydrops",
  "Meningitis",
  "brain fever",
  "cerebrospinal fever",
  "spinal meningitis",
  "aseptic meningitis",
  "bacterial meningitis",
  "viral meningitis",
  "fungal meningitis",
  "Menopause",
  "Menorrhagia",
  "heavy menstrual bleeding",
  "Mesenteric Ischemia",
  "Intestinal Ischemia",
  "Bowel Ischemia",
  "Intestinal Infarction",
  "Bowel Infarction",
  "Mesenteric Infarction",
  "Mesenteric Artery Ischemia",
  "Mesenteric Vein Ischemia",
  "Mesenteric ischemia",
  "Mesothelioma",
  "Malignant Mesothelioma",
  "Pleural Mesothelioma",
  "Peritoneal Mesothelioma",
  "Pericardial Mesothelioma",
  "Malignant Pleural Mesothelioma",
  "Malignant Peritoneal Mesothelioma",
  "Malignant Pericardial Mesothelioma",
  "Migraine",
  "Headache",
  "Migraine headaches",
  "miscarriage",
  "spontaneous abortion",
  "pregnancy loss",
  "Mitral regurgitation",
  "MV regurgitation",
  "MR",
  "Mitral insufficiency",
  "Mitral valve regurgitation",
  "Mitral valve insufficiency",
  "mitral stenosis",
  "mitral valve stenosis",
  "Molar pregnancy",
  "Hydatidiform mole",
  "GTD",
  "Infectious mononucleosis",
  "Glandular fever",
  "Kissing disease",
  "Motion sickness",
  "Travel sickness",
  "Kinetosis",
  "Sea sickness",
  "Car sickness",
  "Multiple myeloma",
  "Plasma cell myeloma",
  "Myelomatosis",
  "Kahler's disease",
  "Multiple sclerosis",
  "MS",
  "Muscle strain or sprain",
  "Muscle pull",
  "Muscle tear",
  "Muscle stretch",
  "Muscle injury",
  "Muscle rupture",
  "Muscular dystrophy",
  "Muscular dystrophies",
  "Myasthenia gravis",
  "Myasthenic crisis",
  "Myocardial Infarction",
  "Heart Attack",
  "Acute Myocardial Infarction",
  "Coronary Thrombosis",
  "Acute Coronary Syndrome",
  "Myocardial ischemia during non-cardiac surgery",
  "Myocardial ischemia in non-cardiac surgical procedures",
  "Cardiac ischemia during non-cardiac surgery",
  "Ischemic heart disease during non-cardiac operation",
  "Perioperative myocardial ischemia \\(non-cardiac\\)",
  "Myocardial oxygen supply-demand mismatch during non-cardiac surgery",
  "Myocarditis",
  "Myocardial inflammation",
  "Myxedema coma",
  "Hypothyroid crisis",
  "Hypothyroidism coma",
  "Thyroid insufficiency coma",
  "Nail fungal infection",
  "Fungal nail infection",
  "Onychomycosis",
  "Nail mycosis",
  "Narcolepsy",
  "Nasal Fracture",
  "Nasal Bone Fracture",
  "Nose Fracture",
  "Fractured Nose",
  "REGENERATE",
  "Nasal Polyps",
  "Near-drowning",
  "Necrotizing enterocolitis",
  "Neonatal necrotizing enterocolitis",
  "Bowel necrosis in newborns",
  "Necrotizing colitis in infants",
  "Necrotizing enteropathy neonatal",
  "Necrotizing fasciitis",
  "Flesh-eating disease",
  "Flesh-eating bacteria",
  "Flesh-eating infection",
  "Neonatal encephalopathy",
  "Neonatal sepsis",
  "Neonatal septicemia",
  "Neonatal bloodstream infection",
  "Neonatal bacteremia",
  "Newborn sepsis",
  "Newborn septicemia",
  "Newborn bloodstream infection",
  "Newborn bacteremia",
  "Nephrotic syndrome",
  "Nephrosis",
  "Neuralgia",
  "Neurofibromatosis",
  "Neuroleptic malignant syndrome",
  "NMS",
  "Neuropathy",
  "Peripheral neuropathy",
  "Polyneuropathy",
  "Peripheral nerve disorder",
  "Nerve damage",
  "Nerve disorder",
  "Nevus Depigmentosus",
  "nightmares",
  "night terrors",
  "Nonalcoholic fatty liver disease",
  "NAFLD",
  "Non-alcoholic fatty liver disease",
  "Nonalcoholic steatohepatitis",
  "NASH",
  "Normal pressure hydrocephalus",
  "NPH",
  "Idiopathic Normal Pressure Hydrocephalus",
  "Communicating Hydrocephalus",
  "Nystagmus",
  "Obsessive-compulsive disorder",
  "OCD",
  "Obsessional neurosis",
  "Compulsive neurosis",
  "Obsessive-compulsive syndrome",
  "Obstructed Bowel or Bowel Perforation",
  "Intestinal Obstruction or Perforation",
  "Bowel Blockage or Rupture",
  "Gastrointestinal Obstruction or Perforation",
  "Bowel Obstruction or Tear",
  "Intestinal Blockage or Tear",
  "Digestive Tract Obstruction or Perforation",
  "Obstructive sleep apnea",
  "OSA",
  "Obstructive sleep apnoea",
  "Obstructive sleep apnoea syndrome",
  "OSA syndrome",
  "Oculogyric Crisis",
  "Open fractures",
  "Compound fractures",
  "Open bone fractures",
  "Open break",
  "Open fracture",
  "Open skeletal injuries",
  "Open bone breaks",
  "Compound bone fracture",
  "Exposed bone fracture",
  "Exposed fractures",
  "Open fractures involving bone",
  "opioid addiction",
  "narcotic addiction",
  "opioid use disorder",
  "Opsoclonus",
  "Osteoarthritis",
  "Degenerative arthritis",
  "Degenerative joint disease",
  "OA",
  "Osteoarthrosis",
  "Osteoporosis",
  "Ovarian cancer",
  "Ovarian Cyst or Tumor",
  "Ovarian Cyst",
  "Ovarian Tumor",
  "Ovarian Neoplasm",
  "Adnexal Mass",
  "Ovarian Growth",
  "Ovarian Lesion",
  "Cystic Ovarian Mass",
  "Ovarian cysts",
  "ovarian cyst",
  "ovarian neoplasm",
  "ovarian tumor",
  "ovarian growth",
  "Ovarian torsion",
  "Adnexal torsion",
  "Ovulatory torsion",
  "Tubo-ovarian torsion",
  "Adnexal twisting",
  "Overactive bladder",
  "OAB",
  "Overdosage",
  "Overconsumption",
  "Toxic dose",
  "Excessive dose",
  "Overindulgence",
  "Poisoning",
  "Paget's disease",
  "Paget disease",
  "Paget's bone disease",
  "osteitis deformans",
  "Pancreatic cancer",
  "cancer of the pancreas",
  "pancreatic carcinoma",
  "pancreatic neoplasm",
  "pancreatic adenocarcinoma",
  "malignant pancreatic tumor",
  "Pancreatitis",
  "Panic disorder",
  "Anxiety attacks",
  "Panic attacks",
  "PD",
  "Panic syndrome",
  "Recurrent panic",
  "Anxiety disorder",
  "Acute anxiety disorder",
  "Paralysis",
  "immobility",
  "incapacity",
  "inactivity",
  "inertia",
  "powerlessness",
  "immovability",
  "Paraneoplastic pemphigus",
  "Parkinson's disease",
  "Parkinson disease",
  "Paralysis agitans",
  "Shaking palsy",
  "Pelvic inflammatory disease",
  "Pelvic inflammatory disorder",
  "Pelvic infection",
  "Pelvic inflammatory syndrome",
  "PID",
  "Pelvic trauma",
  "Pelvic injury",
  "Pelvic fracture",
  "Pelvic crush injury",
  "Pelvic disruption",
  "Pelvic break",
  "Pelvic bone injury",
  "Pelvic harm",
  "Pelvic damage",
  "Peptic ulcer disease",
  "Peptic ulcer",
  "Stomach ulcer",
  "Gastric ulcer",
  "Doudenal ulcer",
  "PUD",
  "Perforated Peptic Ulcer",
  "Peptic Ulcer Perforation",
  "Perforated Gastric Ulcer",
  "Perforated Duodenal Ulcer",
  "Stomach Ulcer Perforation",
  "Perforated Viscous",
  "Perforated Gelatinous",
  "Perforated Gooey",
  "Perforated Sticky",
  "Perforated Syrupy",
  "Perforated Thick",
  "Perforated Viscid",
  "Perforated Slimy",
  "Perforated Adhesive",
  "Perforated Clammy",
  "Perforated Gel-like",
  "Perforated viscus",
  "Perforated internal organ",
  "Organ perforation",
  "Visceral perforation",
  "Pericarditis",
  "Inflammation of the pericardium",
  "Peripheral artery disease",
  "Peripheral arterial disease",
  "Peripheral artery occlusive disease",
  "Peripheral vascular disease",
  "Lower extremity arterial disease",
  "Peripheral artery stenosis",
  "Peripheral artery insufficiency",
  "Peripheral artery atherosclerosis",
  "Peripheral neuropathies",
  "PN",
  "Peripheral neuritis",
  "Peripheral nerve pain",
  "Peritonitis",
  "Peritonsillar Abscess",
  "Quinsy",
  "Tonsillar Abscess",
  "Peritonsillitis",
  "Pesticide exposure",
  "Pesticide contact",
  "Pesticide contamination",
  "Induratio penis plastica",
  "IPP",
  "Peyronie disease",
  "fibrous penis condition",
  "Pheochromocytoma crisis",
  "Pheochromocytoma hypertensive crisis",
  "Catecholamine crisis",
  "Catecholamine-secreting tumor crisis",
  "Adrenal medulla crisis",
  "Adrenal pheochromocytoma crisis",
  "Adrenaline crisis",
  "Paraganglioma crisis",
  "Phobias",
  "Fears",
  "Anxieties",
  "Terrors",
  "Dreads",
  "Panic",
  "Apprehensions",
  "Distresses",
  "Worries",
  "Pityriasis Alba",
  "Pityriasis Rosea",
  "Placental abruption",
  "Abruption placentae",
  "Premature separation of the placenta",
  "Abruptio placentae",
  "Pneumonia",
  "lung inflammation",
  "pneumonitis",
  "alveolitis",
  "pulmonary infection",
  "bronchopneumonia",
  "lobar pneumonia",
  "pneumothorax",
  "collapsed lung",
  "Polycystic kidney disease",
  "PKD",
  "Polycystic renal disease",
  "Polycystic Ovary Syndrome",
  "PCOS",
  "Stein-Leventhal Syndrome",
  "Hyperandrogenic Anovulation",
  "Polymyalgia rheumatica",
  "Post-traumatic stress disorder",
  "PTSD",
  "Posterior reversible encephalopathy syndrome ",
  "PRES",
  "Postpartum depression",
  "Postnatal depression",
  "Peripartum depression",
  "Puerperal psychosis",
  "Postnatal mood disorder",
  "Postpartum mood disorder",
  "Postpartum hemorrhage",
  "PPH",
  "Postpartum bleeding",
  "Post-delivery hemorrhage",
  "Puerperal hemorrhage",
  "Puerperal bleeding",
  "Pre-eclampsia",
  "Preeclampsia",
  "Toxemia of pregnancy",
  "Hypertensive disorder of pregnancy",
  "Gestational hypertension with proteinuria",
  "Gestational toxemia",
  "Premenstrual syndrome",
  "PMS",
  "Pressure ulcers",
  "Decubitus ulcers",
  "Bedsores",
  "Pressure sores",
  "Prostate cancer",
  "Psoriasis",
  "Psoriatic arthritis",
  "Psychotic Disorders",
  "Psychoses",
  "Mental Psychosis",
  "Severe Mental Disorder",
  "Psychotic Illness",
  "Mental Derangement",
  "Psychopathy",
  "Pulmonary edema",
  "Pulmonary oedema",
  "Hydropneumonia",
  "Lung edema",
  "Lung oedema",
  "Acute pulmonary edema",
  "Chronic pulmonary edema",
  "Cardiogenic pulmonary edema",
  "Non-cardiogenic pulmonary edema",
  "Frothy lung",
  "Pulmonary Embolism",
  "PE",
  "Pulmonary Thromboembolism",
  "Lung Embolism",
  "Embolism of lung",
  "Thromboembolic disease of the lung",
  "Pulmonary fibroses",
  "Pulmonary fibrosis \\(disorder\\)",
  "Pulmonary fibrosiss",
  "Pulmonary fibrotic disorder",
  "Pulmonary fibrotic disorders",
  "Rabies",
  "Hydrophobia",
  "Lyssa",
  "Radiation exposure",
  "Raynaud's disease",
  "Raynaud's phenomenon",
  "Raynaud's syndrome",
  "Primary Raynaud's",
  "Secondary Raynaud's",
  "Raynaud's disorder",
  "Raynaud's condition",
  "Reactive arthritis",
  "Rectal neoplasm",
  "Respiratory distress",
  "Acute respiratory distress",
  "Respiratory failure",
  "Breathing difficulty",
  "Dyspnea",
  "Acute respiratory failure",
  "Chronic respiratory failure",
  "Respiratory insufficiency",
  "Respiratory arrest",
  "Hypoxemic respiratory failure",
  "Hypercapnic respiratory failure",
  "Pulmonary failure",
  "Respiratory dysfunction",
  "Restless Legs Syndrome",
  "Jimmy Legs",
  "Retinal artery occlusion",
  "Central retinal artery occlusion",
  "CRAO",
  "Branch retinal artery occlusion",
  "BRAO",
  "Retinal detachment",
  "Retinal vein occlusion",
  "Branch retinal vein occlusion",
  "Central retinal vein occlusion",
  "Vein occlusion \\(retinal\\)",
  "Retinal branch vein occlusion",
  "Retinal central vein occlusion",
  "Retroperitoneal abscess",
  "Retroperitoneal infection",
  "Retroperitoneal space abscess",
  "Retroperitoneal phlegmon",
  "Perirenal abscess",
  "Perinephric abscess",
  "Retroperitoneal pus accumulation",
  "Rhabdomyolysis",
  "Rheumatoid arthritis",
  "RA",
  "Rheumatoid polyarthritis",
  "Rheumatoid disease",
  "Ringworm",
  "Rosacea",
  "Rotator cuff injury",
  "Rubella",
  "Ruptured Abdominal Aortic Aneurysm",
  "Ruptured abdominal aortic aneurysm",
  "Ruptured AAA",
  "RAAA",
  "Ruptured aortic aneurysm",
  "Exploded abdominal aortic aneurysm",
  "Ruptured ectopic pregnancy",
  "Burst ectopic pregnancy",
  "Torn ectopic pregnancy",
  "Perforated ectopic pregnancy",
  "Fragmented ectopic pregnancy",
  "Broken ectopic pregnancy",
  "Ruptured ovarian cyst",
  "Perforated ovarian cyst",
  "Exploded ovarian cyst",
  "Burst ovarian cyst",
  "Salmonella infection",
  "Salmonellosis",
  "Sarcoidosis",
  "Scarlet Fever",
  "Scarlatina",
  "Schizophrenia",
  "Sciatica",
  "Scoliosis",
  "Seborrheic dermatitis",
  "Seborrheic eczema",
  "Seborrheic psoriasis",
  "Cradle cap",
  "Petaloid seborrheic eczema",
  "Seizure",
  "Convulsion",
  "Fits",
  "Epileptic attack",
  "seizures",
  "Sepsis",
  "Septic arthritis",
  "Pyogenic arthritis",
  "Suppurative arthritis",
  "Bacterial arthritis",
  "Purulent arthritis",
  "Infectious arthritis",
  "Septic shock",
  "Septicemia shock",
  "Sepsis-induced hypotension",
  "Sever sepsis shock",
  "Sepsis-related shock",
  "Severe infection shock",
  "Septicemia",
  "Blood poisoning",
  "Systemic inflammatory response syndrome \\(SIRS\\)",
  "Bacteremia with sepsis",
  "Severe allergic skin reactions",
  "Severe asthma attack",
  "Acute asthma exacerbation",
  "Status asthmaticus",
  "Severe bronchial asthma",
  "Severe asthmatic episode",
  "Severe burns",
  "Third-degree burns",
  "Full-thickness burns",
  "Sexually transmitted infections",
  "STIs",
  "STDs",
  "Sexually transmitted diseases",
  "Venereal diseases",
  "Shoulder impingement syndrome",
  "Rotator cuff impingement",
  "Subacromial impingement syndrome",
  "Bursitis of the shoulder",
  "Swimmer's shoulder",
  "Painful arc syndrome",
  "Sinus infection",
  "Sinusitis",
  "Sinus disease",
  "Sinus disorder",
  "Nasal infection",
  "Nasal sinus infection",
  "Rhinosinusitis",
  "Sjogren's",
  "Sjogren's Syndrome",
  "Skin cancer",
  "Skin carcinoma",
  "Cutaneous carcinoma",
  "Dermal cancer",
  "Sleep Apnea",
  "Obstructive Sleep Apnea",
  "Hypopnea Syndrome",
  "Sleep-Disordered Breathing",
  "sleep apnea",
  "obstructive sleep apnea",
  "Sleep deprivation",
  "Sleep disorders",
  "insomnia",
  "narcolepsy",
  "restless legs syndrome",
  "hypersomnia",
  "parasomnia",
  "circadian rhythm disorders",
  "sleep disturbance",
  "somnopathy",
  "dyssomnia",
  "Smallpox",
  "Variola",
  "Variola major",
  "Variola minor",
  "Snake bites",
  "Serpent bites",
  "Reptile bites",
  "Viper bites",
  "Cobra bites",
  "Adder bites",
  "Boa bites",
  "Pit viper bites",
  "Rattlesnake bites",
  "social anxiety",
  "social anxiety disorder",
  "Soft tissue injuries",
  "Spinal cord injury",
  "Spinal cord trauma",
  "Spinal cord damage",
  "SCI",
  "Spinal trauma",
  "Spinal injury",
  "Spinal cord ischemia",
  "Spinal ischemia",
  "Ischemic myelopathy",
  "Spinal epidural abscess",
  "Epidural spinal abscess",
  "Spinal epidural phlegmon",
  "Vertebral canal abscess",
  "Intraspinal abscess",
  "Spinal extradural abscess",
  "Spinal stenosis",
  "cervical stenosis",
  "thoracic stenosis",
  "lumbar stenosis",
  "vertebral canal stenosis",
  "spinal canal narrowing",
  "spinal narrowing",
  "central spinal stenosis",
  "foraminal stenosis",
  "Splenomegaly",
  "Enlarged spleen",
  "Sprains and strains",
  "Injuries",
  "Muscle injuries",
  "Ligament injuries",
  "Tendon injuries",
  "Muscle strains",
  "Ligament sprains",
  "Staph infection",
  "Staphylococcal infection",
  "Staphylococcus infection",
  "Staphylococcal bacteria infection",
  "Skin staph infection",
  "Status epilepticus",
  "SE",
  "Generalized convulsive status epilepticus",
  "GCS",
  "Continuous seizure state",
  "Seizure emergency",
  "Prolonged seizure",
  "Epileptic fit status",
  "Severe seizure episode",
  "Unremitting seizure",
  "Stevens-Johnson syndrome",
  "SJS",
  "Strep throat",
  "Streptococcal pharyngitis",
  "Streptococcal sore throat",
  "Streptococcal tonsillitis",
  "Streptococcal Pharyngitis",
  "Strep Throat",
  "Streptococcal Sore Throat",
  "Group A Streptococcal Pharyngitis",
  "Streptococcal Tonsillitis",
  "Stroke",
  "Apoplexy",
  "Cerebrovascular accident",
  "CVA",
  "Blood clot in the brain",
  "Brain attack",
  "Subarachnoid hemorrhage",
  "SAH",
  "Subcutaneous panniculitis",
  "Lobular panniculitis",
  "Sclerosing panniculitis",
  "Nodular panniculitis",
  "Substance abuse",
  "Drug abuse",
  "Chemical abuse",
  "Substance use disorder",
  "Narcotic abuse",
  "Drug misuse",
  "Substance dependency",
  "Dependence on drugs",
  "Psychoactive substance abuse",
  "Intoxicant abuse",
  "Substance abuse/withdrawal",
  "Addiction",
  "Dependence",
  "Substance dependence",
  "Substance use disorder ħ",
  "Suicidal ideation",
  "Sunburn",
  "Sunscald",
  "Solar erythema",
  "Solar dermatitis",
  "Sun poisoning",
  "Photodermatitis",
  "Erythema solare",
  "Suspected skin cancer",
  "Possible skin cancer",
  "Potential skin cancer",
  "Probable skin cancer",
  "Anticipated skin cancer",
  "Presumed skin cancer",
  "Questionable skin cancer",
  "Unconfirmed skin cancer",
  "Speculated skin cancer",
  "Indicated skin cancer",
  "Predicted skin cancer",
  "Systemic lupus erythematosus",
  "SLE",
  "Takotsubo cardiomyopathy",
  "Stress cardiomyopathy",
  "Broken heart syndrome",
  "Apical ballooning syndrome",
  "Transient left ventricular apical ballooning",
  "Amphora cardiomyopathy",
  "Temporomandibular joint disorder",
  "TMJ disorder",
  "TMJ syndrome",
  "TMD",
  "Temporomandibular dysfunction",
  "Temporomandibular joint dysfunction",
  "Temporomandibular joint syndrome",
  "Temporomandibular joint pain",
  "Temporomandibular joint disease",
  "Tension pneumothorax",
  "Testicular cancer",
  "Tetanus",
  "Lockjaw",
  "Thrombocytopenia",
  "Thyroid cancer",
  "Thyroid carcinoma",
  "Malignant thyroid neoplasm",
  "Papillary thyroid cancer",
  "Follicular thyroid cancer",
  "Medullary thyroid cancer",
  "Anaplastic thyroid cancer",
  "Thyroid disorders",
  "Thyroid disease",
  "Thyroid dysfunction",
  "Thyroid conditions",
  "Thyroid issues",
  "Thyroid storm",
  "Thyrotoxic crisis",
  "Tinea Versicolor",
  "Pityriasis Versicolor",
  "Tinnitus",
  "Tonsillitis",
  "Inflamed tonsils",
  "Swollen tonsils",
  "Acute tonsillitis",
  "Painful tonsils",
  "Enlarged tonsils",
  "Tourette syndrome",
  "Toxic epidermal necrolysis",
  "Steven-Johnson syndrome",
  "Lyell syndrome",
  "Toxic fume inhalation",
  "Poisonous gas inhalation",
  "Hazardous vapor inhalation",
  "Harmful fume inhalation",
  "Dangerous gas inhalation",
  "Toxic gas inhalation",
  "Poisonous vapor inhalation",
  "Noxious fume inhalation",
  "Noxious gas inhalation",
  "Toxic megacolon",
  "Acute megacolon",
  "Fulminant colonic dilation",
  "Toxic shock syndrome",
  "Staphylococcal toxic shock syndrome",
  "Streptococcal toxic shock syndrome",
  "Transient ischemic attack",
  "Mini-stroke",
  "TIA",
  "Minor stroke",
  "Brain trauma",
  "Intracranial injury",
  "Cranial injury",
  "Blunt head trauma",
  "Closed head injury",
  "Penetrating head injury",
  "Tuberculosis",
  "TB",
  "Phthisis",
  "Consumption",
  "Mycobacterium tuberculosis infection",
  "Pulmonary tuberculosis",
  "Typhoid Fever",
  "Enteric Fever",
  "Salmonella Typhi Infection",
  "Typhus Abdominalis",
  "Infantile Remittent Fever",
  "Pythogenic Fever",
  "Slow Fever",
  "Chronic ulcerative colitis",
  "Colitis ulcerosa",
  "UC",
  "Upper airway obstruction",
  "UAO",
  "Upper airway constriction",
  "Upper airway blockage",
  "Upper airway occlusion",
  "Upper airway narrowing",
  "Upper respiratory tract infection",
  "Acute nasopharyngitis",
  "Acute rhinitis",
  "Acute rhinopharyngitis",
  "Rhinitis",
  "Cold",
  "Urethritis",
  "urinary incontinence",
  "Urinary Obstruction",
  "Bladder Outlet Obstruction",
  "Urinary Tract Obstruction",
  "Urethral Obstruction",
  "UT Obstruction",
  "Urinary Flow Obstruction",
  "Urinary Blockage",
  "Urological Obstruction",
  "Urinary Retention",
  "Inability to Urinate",
  "Bladder Retention",
  "Incomplete Bladder Emptying",
  "Uchuria",
  "Urinary Tract Infection",
  "UTI",
  "Bladder Infection",
  "Uterine cancer",
  "endometrial cancer",
  "uterus cancer",
  "carcinoma of the uterus",
  "corpus cancer",
  "malignant neoplasm of the uterus",
  "primary uterine cancer",
  "Uterine myomas",
  "Fibromyomas",
  "Varicose veins",
  "Varicosities",
  "Varices",
  "Dilated veins",
  "Spider veins",
  "Venous insufficiency",
  "Phlebectasia",
  "Swollen veins",
  "Vasculitis",
  "Vertigo",
  "dizziness",
  "giddiness",
  "lightheadedness",
  "unsteadiness",
  "spinning sensation",
  "Vitamin deficiencies",
  "Nutrient deficiencies",
  "Micronutrient deficiencies",
  "Hypovitaminosis",
  "Avitaminosis",
  "Vitamin insufficiencies",
  "Nutrient insufficiencies",
  "Micronutrient insufficiencies",
  "Vitamin shortages",
  "Vitamin insufficiency disorders",
  "Vitiligo",
  "Volvulus",
  "Von willebrand disease",
  "West Nile Virus",
  "Whiplash",
  "Whipple's disease",
  "Wilson's disease",
  "yeast infection",
  "candidaiasis",
  "vaginal candidiasis",
  "Zika virus",
];
