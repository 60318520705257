import React, { useState } from "react";
import firebaseApp, { db } from "../../firebase";
import "firebase/compat/storage";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import capitalize from "components/helpers/capitalize";
import useSearch from "hooks/useSearch";
import getAddress from "components/helpers/getAddress";

const AddressSettings = ({ location, visible, close, setSubmitted }) => {
  const address = useSearch("");
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState();
  const [firebaseError, setFirebaseError] = useState();

  const updateLocation = (updatedLocation) => {
    db.collection("locations")
      .doc(String(location.id))
      .update(updatedLocation)
      .then((l) => {
        setLoading(false);
        setSubmitted("Your facility address has been updated.");
        close();
      });
  };

  // If location data not loaded yet, return loading screen
  if (!location) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={visible}
        onClick={close}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  const onSubmit = ({ newlocation, group }) => {
    if (!newlocation && !group) {
      setFirebaseError("Please update at least one field.");
      return;
    }

    setFirebaseError();
    setLoading(true);
    setSubmitted(false);
    let updatedLocation = {};

    if (newlocation) {
      const lookup = (name) => newlocation.context.find((a) => a?.id.startsWith(name))?.text;
      const address = getAddress(newlocation);
      const city = lookup("place");
      const state = lookup("region");
      const zip = lookup("postcode");

      updatedLocation = {
        lat: newlocation.center[1],
        lng: newlocation.center[0],
        address,
        city,
        state,
        zip,
      };
    }

    if (group) {
      updatedLocation.group = group;
    }

    updateLocation(updatedLocation);
  };

  let currentAddress = capitalize(
    `${location?.address}, ${location?.city}, ${location?.state} ${location?.zip}`
  );

  return (
    <Dialog open={visible} onClose={close}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Update Your Facility Information</DialogTitle>

        <DialogContent>
          <DialogContentText variant="body2" sx={{ pb: 3 }}>
            Your current address is <strong>{currentAddress}</strong>.
          </DialogContentText>

          <Controller
            name="newlocation"
            control={control}
            rules={{
              validate: {
                required: (value) => {
                  if (value && !getAddress(value))
                    return "We weren't able to get an address from this location. Please choose a different address.";
                },
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                id="newlocation"
                options={address.suggestions}
                onChange={(event, item) => {
                  onChange(item);
                }}
                getOptionLabel={(option) => option.place_name || ""}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={value || null}
                noOptionsText={"No results found"}
                loading={address.loading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(newValue) => {
                      address.onChange(newValue);
                    }}
                    placeholder={currentAddress}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {address.loading ? <CircularProgress color="primary" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    label="Facility Address"
                    variant="standard"
                    error={!!errors?.newlocation}
                    helperText={errors?.newlocation?.message}
                  />
                )}
              />
            )}
          />

          <Controller
            name="group"
            control={control}
            defaultValue={location?.group || ""}
            render={({ field }) => (
              <TextField
                id="group"
                label="Facility Group (optional)"
                type="text"
                InputLabelProps={{ shrink: true }}
                variant="standard"
                fullWidth
                sx={{ mt: 2 }}
                {...field}
              />
            )}
          />

          {firebaseError ? (
            <FormHelperText error sx={{ mt: 1 }}>
              {firebaseError}
            </FormHelperText>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Close</Button>
          <LoadingButton
            loading={loading}
            type="submit"
            disabled={loading}
            autoFocus
            variant="contained"
          >
            Update Branding
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddressSettings;
