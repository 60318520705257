const getAddress = ({ address, properties, text }) => {
  // Get the address of the clinic because Mapbox doesn't make it easy

  // Get the explicit address if Mapbox returns it
  if (properties.address) return properties.address;

  // Otherwise, get the address by piecing it together
  if (address) return `${address} ${text}`;

  // If we can't get anything, return an error
  return undefined;
};

export default getAddress;
