import React, { useState } from "react";
import { differenceInDays } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import capitalize from "../helpers/capitalize";

import Loading from "../Loading";

import Error from "../styled/Error";
import InputField from "../styled/InputField";
import Text from "../styled/Text";
import H2 from "../styled/H2";
import H3 from "../styled/H3";
import ButtonCaptcha from "../styled/ButtonCaptcha";
import MuiPhoneNumber from "material-ui-phone-number";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Link,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ReCaptcha } from "react-recaptcha-v3";
const QueueForm = ({
  textSequence,
  queueEnabled,
  queueNumber,
  queueCap,
  queueLength,
  locationName,
  locationRef,
  firebase,
  db,
}) => {
  const title = locationName ? capitalize(locationName) : undefined;
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  let lastSignedUp = localStorage["queueSubmitted"];

  const onSubmit = ({ phone }) => {
    setLoading(true);
    const batch = db.batch();

    batch.update(db.collection("locations").doc(locationRef), {
      queueNumber: firebase.firestore.FieldValue.increment(1),
      queue: firebase.firestore.FieldValue.arrayUnion({
        id: queueNumber || 0,
        date: Date.now(),
        phone,
      }),
    });

    const body =
      (textSequence
        ? textSequence[0]
        : `Thank you for joining the Virtual Queue at ${title}. You should expect several more texts to guide you through this process.  If your phone dies (they do that), proceed directly to ${title} to check in, and tell them your patient number in the Virtual Queue.`) +
      ` You are patient #${queueNumber}.`;

    batch.set(db.collection("messages").doc(), {
      to: phone,
      body,
      location: locationRef,
      date: Date.now(),
    });

    batch.commit().then(() => {
      setLoading(false);
      setSubmitted(true);
      localStorage["queueSubmitted"] = new Date();
    });
  };

  if (queueLength === undefined) {
    queueLength = 0;
  }

  // Only allow one virtual queue signup a day on production
  if (
    process.env.REACT_APP_FIREBASE_PROJECT_ID === "hlthdsk" &&
    differenceInDays(new Date(), new Date(lastSignedUp)) <= 1
  )
    return null;

  return (
    <>
      {queueEnabled && queueLength < queueCap && (
        <Box sx={{ maxWidth: 420, m: "auto", mt: 2, mb: 2 }}>
          {submitted ? (
            <Box>
              <Typography variant="h5">Success</Typography>

              <Typography variant="body">
                You should receive a series of texts to guide you through the process. If you have
                problems receiving texts, please proceed to {locationName}.
              </Typography>

              {/* <br /> */}
              {/* <a href="https://interlockhealth.com/hclm" target="_blank">
                <Button>Create or Update your HealthCard</Button>
              </a> */}

              <List dense={true} sx={{ listStyleType: "disc", pl: 2, fontSize: 14 }}>
                <ListItem sx={{ display: "list-item" }}>
                  You won't receive a text if your phone isn't on.
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  You'll have a 30 minute arrival window after receiving that text.
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  Never use this system if you have an emergency. Dial 911.
                </ListItem>
              </List>

              {/* <ShareButtons large /> */}
            </Box>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* TODO: ADD VALIDATION */}
              <Controller
                name="phone"
                control={control}
                rules={{
                  required: "You must enter a phone number.",
                }}
                render={({ field }) => (
                  <MuiPhoneNumber
                    id="phone"
                    defaultCountry={"us"}
                    onlyCountries={["us"]}
                    disableCountryCode={true}
                    disableDropdown={true}
                    variant="standard"
                    label="Phone"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    sx={{ mb: 1 }}
                    placeholder="123 456 7890"
                    {...field}
                  />
                )}
              />
              {errors?.phone ? <FormHelperText error>{errors.phone.message}</FormHelperText> : null}
              <Controller
                name="consent"
                control={control}
                rules={{
                  required: "You must agree to the conditions.",
                }}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} />}
                    slotProps={{ typography: { position: "relative", fontSize: 13, mb: 1 } }}
                    sx={{ alignItems: "flex-start" }}
                    label="I agree to receive customer care messaging from HLTHDSK at the phone number provided above. I understand I will receive several messages designed to decrease my time in the waiting room. Data rates may apply, reply STOP to opt out."
                  />
                )}
              />
              {errors?.consent ? (
                <FormHelperText error sx={{ mt: 0, mb: 1 }}>
                  {errors.consent.message}
                </FormHelperText>
              ) : null}
              <LoadingButton
                loading={loading}
                disabled={loading}
                variant="contained"
                fullWidth
                type="submit"
              >
                Join Virtual Queue
              </LoadingButton>

              <Typography variant="body2" sx={{ textAlign: "center", mt: 2, mb: -1 }}>
                <Link href="/privacy-policy">Privacy Policy</Link> |{" "}
                <Link href="/terms-of-use">Terms of Use</Link>
              </Typography>

              <ReCaptcha sitekey="6LdFylEaAAAAAFxT6Keio4PQU5sg1jhvn7BE5mPq" action="submit" />
            </form>
          )}
        </Box>
      )}
    </>
  );
};

export default QueueForm;
